<template>
  <div class="row no-gutters">
    <div :class="getStatusBadge(status)">
      <div class="badge-text-sm">
        {{ getStatus(status) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default : this
    }
  },
  methods: {
    getStatusBadge (status) {
      switch (status) {
        case 'paid':
          return 'badge-paid-confirmation';
        case 'failed':
          return 'badge-available-failed-confirmation';
        case 'in_transit':
          return 'badge-in-transit-confirmation';
        default:
          return 'badge-available-error-confirmation';
      }
    },
    getStatus (status) {
      switch (status) {
        case 'paid':
        case 'failed':
        case 'in_transit':
          return this.$t('views.dashboard.content.payment.tabs.' + status);
        default :
          return this.$t('views.dashboard.content.payment.tabs.unknown');
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/payout/_payout-badge.scss";
</style>
